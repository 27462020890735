<template>
    <div>
        <v-container>
          <v-card style="background-color: #F5F5F5" elevation="3">
            <div class="mt-2 mb-5 text-h5 text-sm-h5 text-md-h4 text-lg-h4 ma-3 text-xl-h4 text-center">
              <span v-if="isNew">Nueva</span>
              <span v-if="!isNew">Editar</span>
              persona
            </div>

            <v-form
              ref="form"
              v-model="isValid"
              autocomplete="off"
            >
            <v-tabs v-model="tab" background-color="transparent" show-arrows>
              <v-tabs-slider color="blue"></v-tabs-slider>

              <v-tab href="#tab-per">Persona</v-tab>
              <v-tab href="#tab-cat">Categorías</v-tab>
              <v-tab href="#tab-emp">Empresas</v-tab>
              <v-tab href="#tab-con">Contactos</v-tab>
              <v-tab href="#tab-ope">Operaciones</v-tab>
              <v-tab href="#tab-art">Artículos</v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab" touchless>
              <v-tab-item :value="'tab-per'">
                <v-card style="background-color: #F5F5F5" flat>
                  <!-- FORMULARIO PERSONA -->
                  <v-row>
                    <v-col class="px-7 mt-4" cols="6" sm="3" md="3" lg="3" xl="2">
                      <v-text-field
                          class="mb-n7"
                          outlined
                          dense
                          text
                          v-model="per.PerCod"
                          label="Código"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="px-7 mt-2 mr-n10" cols="12" sm="7" md="5" lg="5" xl="3">
                      <UsrFind :label="'Usuario'" class="mb-n7" v-model="per.Usr" :readonly="true" :loadOnMount="true"></UsrFind>
                    </v-col>
                    <v-col v-if="per.Usr == null" class="px-7 mt-2">
                      <v-btn color="primary"
                      :disabled="per.PerNom == ''"
                      :loading="loading"
                      @click="onBtnCrearUsuario">
                      Crear usuario
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="px-7 mt-2" cols="12" sm="7" md="5" lg="5" xl="3">
                      <v-text-field
                          class="mb-n7"
                          outlined
                          dense
                          text
                          required
                          :rules="[v => !!v || 'El nombre es obligatorio']"
                          v-model="per.PerNom"
                          label="Nombre *"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="px-7 mt-2" cols="12" sm="7" md="5" lg="5" xl="3">
                      <v-text-field
                          class="mb-n7"
                          outlined
                          dense
                          text
                          v-model="per.PerNomCial"
                          label="Nombre comercial"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="px-7 mt-2" cols="12" sm="4" md="3" lg="3" xl="2">
                      <v-text-field
                          class="mb-n7"
                          outlined
                          dense
                          text
                          v-model="per.PerAbr"
                          label="Abreviatura"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="px-7 mt-2" cols="12" sm="4" md="3" lg="3" xl="2">
                      <v-text-field
                          class="mb-n7"
                          outlined
                          dense
                          text
                          v-model="per.PerNif"
                          label="NIF"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="px-7 mt-2" cols="12" sm="7" md="5" lg="5" xl="3">
                      <PerFind class="mb-n7" ref="perCtrl" v-model="per.PerSup" label="Superior"></PerFind>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="px-7 mt-2" cols="12" sm="8" md="6" lg="6" xl="4">
                      <v-textarea
                          class="mt-0 mb-n7"
                          outlined
                          auto-grow
                          v-model="per.PerObs"
                          label="Observaciones"
                      ></v-textarea>
                    </v-col>
                  </v-row>
                </v-card>
              </v-tab-item>
              <v-tab-item :value="'tab-cat'">
                <v-card style="background-color: #F5F5F5" flat>
                  <v-row class="mt-0">
                    <!-- CATEGORIAS -->
                    <v-col class="px-7" cols="12" sm="9" md="8" lg="7" xl="6">
                      <!-- Botón añadir categoría -->
                      <v-btn
                      :loading="loading"
                      :disabled="editPerCat"
                      @click="onBtnNuevaCategoria"
                      color="primary"
                      small
                      class="mb-2">
                        Añadir categoría
                      </v-btn>
                      
                      <v-data-table
                        :headers="perCatHeaders"
                        :items="perCatDataRows"
                        :options.sync="perCatOptions"
                        :footer-props="{itemsPerPageOptions: [100]}"
                        :server-items-length="perCatTotalDataRows"
                        :loading="perCatLoading"
                        style="overflow: scroll"
                        mobile-breakpoint="0"
                        class="elevation-1"
                        dense
                        >
                          <!-- eslint-disable-next-line vue/valid-v-slot -->
                          <template v-slot:item.Opt="{ item }">
                            <v-btn
                              color="error"
                              class="mr-2 my-1"
                              small
                              @click.stop="onBtnShowPerCatDeleteModal(item)"
                            >
                              <v-icon>
                                mdi-delete
                              </v-icon>
                            </v-btn>
                          </template>
                          <!-- NUEVA CATEGORÍA FORMULARIO -->
                          <template v-if="editPerCat" slot="body.append">
                            <tr class="new-row">
                                <td>
                                  <PerCatFind class="mb-n7" ref="perCatCtrl" v-model="newPerCat" :perCatToHide="perCatDataRows" label="Categoría"></PerCatFind>
                                </td>
                                <td style="min-width: 250px" align ="right">
                                  <v-btn
                                    :loading="perCatLoading"
                                    :disabled="newPerCat == null"
                                    @click="onBtnSaveCategoria"
                                    color="primary"
                                    class="mt-2 mb-4 mr-2"
                                    small
                                  >
                                    Aceptar
                                  </v-btn>
                                    
                                  <v-btn
                                    @click="onBtnCancelCategoria"
                                    class="mt-2 mb-4 mr-2"
                                    small
                                  >
                                    Cancelar
                                  </v-btn>
                                </td>
                            </tr>
                          </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-card>
              </v-tab-item>
              <v-tab-item :value="'tab-emp'" eager>
                <v-card style="background-color: #F5F5F5" flat>
                  <v-row class="mt-0">
                    <!-- EMPRESAS -->
                    <v-col class="px-7" cols="12" sm="9" md="8" lg="7" xl="6">
                      <EmpresasRelacionadas
                        ref="relatedEmps"
                        :empList="empDataList" 
                        :empTotal="empDataTotal" 
                        :loading="loading" 
                        @update-data="updateEmpData"
                        @emp-deleted="empDeleted"
                      ></EmpresasRelacionadas>
                    </v-col>
                  </v-row>
                </v-card>
              </v-tab-item>
              <v-tab-item :value="'tab-con'">
                <v-card style="background-color: #F5F5F5" flat>
                  <v-row class="mt-0">
                    <!-- CONTACTOS -->
                    <v-col class="px-7" cols="12" sm="12" md="12" lg="12" xl="12">
                      <!-- Boton añadir contacto -->
                      <v-btn
                      :loading="loading"
                      :disabled="editNewPerCon"
                      @click="onBtnNuevoContacto"
                      color="primary"
                      small
                      class="mb-2">
                        Añadir contacto
                      </v-btn>

                      <v-data-table
                        :headers="perConHeaders"
                        :items="perConDataRows"
                        :item-class="isEditRow"
                        :options.sync="perConOptions"
                        :footer-props="{itemsPerPageOptions: [100]}"
                        :server-items-length="perConTotalDataRows"
                        :loading="perConLoading"
                        @click:row="onClickRowEditarPerCon"
                        style="overflow: scroll"
                        mobile-breakpoint="0"
                        class="elevation-1 perConDt"
                        dense
                        >
                          <!-- eslint-disable-next-line vue/valid-v-slot -->
                          <template v-slot:item.PerConCod="{ item }">
                              <span v-if="!item.edit">{{item.PerConCod}}</span>
                              <div v-else class="px-0 py-2" style="min-width: 90px">
                                <v-text-field class="mb-n1" outlined dense text
                                  v-model="perConEditData.PerConCod"
                                  label="Código"
                                  :hide-details="true"
                                ></v-text-field>
                              </div>
                          </template>
                          <!-- eslint-disable-next-line vue/valid-v-slot -->
                          <template v-slot:item.PerConNom="{ item }">
                              <span v-if="!item.edit">{{item.PerConNom}}</span>
                              <div v-else class="px-0 py-1" style="min-width: 115px">
                                <v-text-field class="mb-n1" outlined dense text
                                  v-model="perConEditData.PerConNom"
                                  label="Nombre"
                                  :hide-details="true"
                                ></v-text-field>
                              </div>
                          </template>
                          <!-- eslint-disable-next-line vue/valid-v-slot -->
                          <template v-slot:item.PerConTel1="{ item }">
                              <span v-if="!item.edit">{{item.PerConTel1}}</span>
                              <div v-else class="px-0 py-1" style="min-width: 115px">
                                <v-text-field class="mb-n1" outlined dense text
                                  v-model="perConEditData.PerConTel1"
                                  label="Teléfono 1"
                                  :hide-details="true"
                                ></v-text-field>
                              </div>
                          </template>
                          <!-- eslint-disable-next-line vue/valid-v-slot -->
                          <template v-slot:item.PerConTel2="{ item }">
                              <span v-if="!item.edit">{{item.PerConTel2}}</span>
                              <div v-else class="px-0 py-1" style="min-width: 115px">
                                <v-text-field class="mb-n1" outlined dense text
                                  v-model="perConEditData.PerConTel2"
                                  label="Teléfono 2"
                                  :hide-details="true"
                                ></v-text-field>
                              </div>
                          </template>
                          <!-- eslint-disable-next-line vue/valid-v-slot -->
                          <template v-slot:item.PerConFax="{ item }">
                              <span v-if="!item.edit">{{item.PerConFax}}</span>
                              <div v-else class="px-0 py-1" style="min-width: 115px">
                                <v-text-field class="mb-n1" outlined dense text
                                  v-model="perConEditData.PerConFax"
                                  label="Fax"
                                  :hide-details="true"
                                ></v-text-field>
                              </div>
                          </template>
                          <!-- eslint-disable-next-line vue/valid-v-slot -->
                          <template v-slot:item.PerConMail="{ item }">
                              <span v-if="!item.edit">{{item.PerConMail}}</span>
                              <div v-else class="px-0 py-1" style="min-width: 215px">
                                <v-text-field class="mb-n1" outlined dense text
                                  v-model="perConEditData.PerConMail"
                                  label="Mail"
                                  :hide-details="true"
                                ></v-text-field>
                              </div>
                          </template>
                          <!-- eslint-disable-next-line vue/valid-v-slot -->
                          <template v-slot:item.PerConObs="{ item }">
                              <span v-if="!item.edit">{{item.PerConObs}}</span>
                              <div v-else class="px-0 py-1" style="min-width: 115px">
                                <v-text-field class="mb-n1" outlined dense text
                                  v-model="perConEditData.PerConObs"
                                  label="Observaciones"
                                  :hide-details="true"
                                ></v-text-field>
                              </div>
                          </template>
                          <!-- eslint-disable-next-line vue/valid-v-slot -->
                          <template v-slot:item.Opt="{ item }">
                            <v-btn v-if="!item.edit"
                              color="error"
                              class="mr-2 my-1"
                              small
                              @click.stop="onBtnShowPerConDeleteModal(item)"
                            >
                              <v-icon>
                                mdi-delete
                              </v-icon>
                            </v-btn>
                            <div v-else class="px-0 py-1" style="min-width: 200px" align ="right">
                              <v-btn
                                :loading="perConLoading"
                                :disabled="perConEditData.PerConNom == null || perConEditData.PerConNom == ''"
                                @click.stop="onBtnEditPerCon(item)"
                                color="primary"
                                class="mt-2 mb-4 mr-2"
                                small
                              >
                                Aceptar
                              </v-btn>
                                
                              <v-btn
                                @click.stop="cancelPerConEdit"
                                class="mt-2 mb-4 mr-2"
                                small
                              >
                                Cancelar
                              </v-btn>
                            </div>
                          </template>
                          <!-- NUEVO CONTACTO FORMULARIO -->
                          <template v-if="editNewPerCon" slot="body.append">
                            <tr class="new-row">
                                <td class="px-1" style="min-width: 90px">
                                  <v-text-field
                                    class="mb-n7"
                                    outlined
                                    dense
                                    text
                                    v-model="newPerCon.PerConCod"
                                    label="Código"
                                  ></v-text-field>
                                </td>
                                <td class="px-1" style="min-width: 115px">
                                  <v-text-field
                                    class="mb-n7"
                                    outlined
                                    dense
                                    text
                                    v-model="newPerCon.PerConNom"
                                    label="Nombre"
                                  ></v-text-field>
                                </td>
                                <td class="px-1" style="min-width: 115px">
                                  <v-text-field
                                    class="mb-n7"
                                    outlined
                                    dense
                                    text
                                    v-model="newPerCon.PerConTel1"
                                    label="Teléfono 1"
                                  ></v-text-field>
                                </td>
                                <td class="px-1" style="min-width: 115px">
                                  <v-text-field
                                    class="mb-n7"
                                    outlined
                                    dense
                                    text
                                    v-model="newPerCon.PerConTel2"
                                    label="Teléfono 2"
                                  ></v-text-field>
                                </td>
                                <td class="px-1" style="min-width: 115px">
                                  <v-text-field
                                    class="mb-n7"
                                    outlined
                                    dense
                                    text
                                    v-model="newPerCon.PerConFax"
                                    label="Fax"
                                  ></v-text-field>
                                </td>
                                <td class="px-1" style="min-width: 215px">
                                  <v-text-field
                                    class="mb-n7"
                                    outlined
                                    dense
                                    text
                                    v-model="newPerCon.PerConMail"
                                    label="Mail"
                                  ></v-text-field>
                                </td>
                                <td class="px-1" style="min-width: 115px">
                                  <v-text-field
                                    class="mb-n7"
                                    outlined
                                    dense
                                    text
                                    v-model="newPerCon.PerConObs"
                                    label="Observaciones"
                                  ></v-text-field>
                                </td>
                                <td class="px-1" style="min-width: 210px" align="right">
                                  <v-btn
                                    :loading="perConLoading"
                                    :disabled="newPerCon.PerConNom == null || newPerCon.PerConNom == ''"
                                    @click="onBtnSavePerCon"
                                    color="primary"
                                    class="mt-2 mb-4 mr-2"
                                    small
                                  >
                                    Aceptar
                                  </v-btn>
                                    
                                  <v-btn
                                    @click="onBtnCancelPerCon"
                                    class="mt-2 mb-4 mr-2"
                                    small
                                  >
                                    Cancelar
                                  </v-btn>
                                </td>
                            </tr>
                          </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-card>
              </v-tab-item>
              <v-tab-item :value="'tab-ope'">
                <v-card style="background-color: #F5F5F5" flat>
                  <v-row class="mt-0">
                    <!-- OPERACIONES -->
                    <v-col class="px-7" cols="12" sm="12" md="12" lg="12" xl="12">
                      <!-- Boton añadir operación -->
                      <v-btn
                      :loading="loading"
                      :disabled="editNewPerOpe"
                      @click="onBtnNuevaOperacion"
                      color="primary"
                      small
                      class="mb-2">
                        Añadir operación
                      </v-btn>

                      <v-data-table
                        :headers="perOpeHeaders"
                        :items="perOpeDataRows"
                        :options.sync="perOpeOptions"
                        :footer-props="{itemsPerPageOptions: [100]}"
                        :server-items-length="perOpeTotalDataRows"
                        :loading="perOpeLoading"
                        @click:row="onClickRowEditarPerOpe"
                        style="overflow: scroll"
                        mobile-breakpoint="0"
                        class="elevation-1 perOpeDt"
                        dense
                        >
                          <!-- eslint-disable-next-line vue/valid-v-slot -->
                          <template v-slot:item.Emp.EmpNom="{ item }">
                              <span v-if="!item.edit">{{item.Emp.EmpNom}}</span>
                              <div v-else class="py-2" style="min-width: 350px">
                                <EmpFind class="mb-n7" ref="editPerOpeEmpCtrl" v-model="perOpeEditData.Emp" label="Empresa"></EmpFind>
                              </div>
                          </template>
                          <!-- eslint-disable-next-line vue/valid-v-slot -->
                          <template v-slot:item.Ope.OpeNom="{ item }">
                              <span v-if="!item.edit">{{item.Ope.OpeNom}}</span>
                              <div v-else class="py-1" style="min-width: 215px">
                                <OpeFind class="mb-n7" ref="editPerOpeOpeCtrl" v-model="perOpeEditData.Ope" label="Operación"></OpeFind>
                              </div>
                          </template>
                          <!-- eslint-disable-next-line vue/valid-v-slot -->
                          <template v-slot:item.Opt="{ item }">
                            <v-btn v-if="!item.edit"
                              color="error"
                              class="mr-2 my-1"
                              small
                              @click.stop="onBtnShowPerOpeDeleteModal(item)"
                            >
                              <v-icon>
                                mdi-delete
                              </v-icon>
                            </v-btn>
                            <div v-else class="py-1" style="min-width: 200px" align ="right">
                              <v-btn
                                :loading="perOpeLoading"
                                :disabled="!isValidPerOpe(perOpeEditData)"
                                @click.stop="onBtnEditPerOpe(item)"
                                color="primary"
                                class="mt-2 mb-4 mr-2"
                                small
                              >
                                Aceptar
                              </v-btn>
                                
                              <v-btn
                                @click.stop="cancelPerOpeEdit"
                                class="mt-2 mb-4 mr-2"
                                small
                              >
                                Cancelar
                              </v-btn>
                            </div>
                          </template>
                          <!-- NUEVA OPERACION FORMULARIO -->
                          <template v-if="editNewPerOpe" slot="body.append">
                            <tr class="new-row">
                                <td style="min-width: 350px">
                                  <EmpFind class="mb-n7" ref="newPerOpeEmpCtrl" v-model="newPerOpe.Emp" label="Empresa"></EmpFind>
                                </td>
                                <td style="min-width: 210px">
                                  <OpeFind class="mb-n7" ref="newPerOpeOpeCtrl" v-model="newPerOpe.Ope" label="Operación"></OpeFind>
                                </td>
                                <td style="min-width: 210px" align="right">
                                  <v-btn
                                    :loading="perOpeLoading"
                                    :disabled="!isValidPerOpe(newPerOpe)"
                                    @click="onBtnSavePerOpe"
                                    color="primary"
                                    class="mt-2 mb-4 mr-2"
                                    small
                                  >
                                    Aceptar
                                  </v-btn>
                                    
                                  <v-btn
                                    @click="onBtnCancelPerOpe"
                                    class="mt-2 mb-4 mr-2"
                                    small
                                  >
                                    Cancelar
                                  </v-btn>
                                </td>
                            </tr>
                          </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-card>
              </v-tab-item>
              <v-tab-item :value="'tab-art'">
                <v-card style="background-color: #F5F5F5" flat>
                  <v-row class="mt-0">
                    <!-- ARTICULOS -->
                    <v-col class="px-7" cols="12" sm="12" md="12" lg="12" xl="12">
                      <!-- Boton añadir artículo -->
                      <v-btn
                      :loading="loading"
                      :disabled="editNewPerTar"
                      @click="onBtnNuevoPerTar"
                      color="primary"
                      small
                      class="mb-2">
                        Añadir artículo
                      </v-btn>

                      <v-data-table
                        :headers="perTarHeaders"
                        :items="perTarDataRows"
                        :options.sync="perTarOptions"
                        :footer-props="{itemsPerPageOptions: [100]}"
                        :server-items-length="perTarTotalDataRows"
                        :loading="perTarLoading"
                        style="overflow: scroll"
                        mobile-breakpoint="0"
                        class="elevation-1 perTarDt"
                        dense
                        >
                          <!-- eslint-disable-next-line vue/valid-v-slot -->
                          <template v-slot:item.FechaDesde="{ item }">
                            {{ formatDate(item.FechaDesde) }}
                          </template>
                          <!-- eslint-disable-next-line vue/valid-v-slot -->
                          <template v-slot:item.FechaHasta="{ item }">
                            {{ formatDate(item.FechaHasta) }}
                          </template>
                          <!-- eslint-disable-next-line vue/valid-v-slot -->
                          <template v-slot:item.Opt="{ item }">
                            <v-btn
                              color="error"
                              class="mr-2 my-1"
                              small
                              @click.stop="onBtnShowPerTarDeleteModal(item)"
                            >
                              <v-icon>
                                mdi-delete
                              </v-icon>
                            </v-btn>
                          </template>
                          <!-- NUEVO ARTICULO FORMULARIO -->
                          <template v-if="editNewPerTar" slot="body.append">
                            <tr class="new-row">
                                <td style="min-width: 325px">
                                  <EmpFind class="mb-n7" ref="newPerTarEmpCtrl" v-model="newPerTar.Emp" label="Empresa"></EmpFind>
                                </td>
                                <td style="min-width: 180px">
                                  <OpeFind class="mb-n7" ref="newPerTarOpeCtrl" v-model="newPerTar.Ope" label="Operación"></OpeFind>
                                </td>
                                <td style="min-width: 230px">
                                  <ArtFind class="mb-n7" ref="newPerTarArtCtrl" v-model="newPerTar.Art" label="Artículo"></ArtFind>
                                </td>
                                <td style="min-width: 175px">
                                  <div class="mb-n7">
                                    <DateFind label="Desde" v-model="newPerTar.FechaDesde"></DateFind>
                                  </div>
                                </td>
                                <td style="min-width: 175px">
                                  <div class="mb-n7">
                                    <DateFind  label="Hasta" v-model="newPerTar.FechaHasta"></DateFind>
                                  </div>
                                </td>
                                <td style="min-width: 235px" align="right">
                                  <v-btn
                                    :loading="perTarLoading"
                                    :disabled="!isValidPerTar(newPerTar)"
                                    @click="onBtnSavePerTar"
                                    color="primary"
                                    class="mt-2 mb-4 mr-2"
                                    small
                                  >
                                    Aceptar
                                  </v-btn>
                                    
                                  <v-btn
                                    @click="onBtnCancelPerTar"
                                    class="mt-2 mb-4 mr-2"
                                    small
                                  >
                                    Cancelar
                                  </v-btn>
                                </td>
                            </tr>
                          </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-card>
              </v-tab-item>
            </v-tabs-items>

            <!-- ACEPTAR / CANCELAR / BORRAR -->
            <v-row class="mt-2" justify="center">
                <v-btn
                    :loading="loading"
                    :disabled="!isValid"
                    @click="onBtnSave"
                    color="primary"
                    class="mt-2 mb-4 mr-2"
                    style="width:100px"
                >
                    Aceptar
                </v-btn>
                
                <v-btn
                    to="/personas"
                    class="mt-2 mb-4 mr-2"
                    style="width:100px"
                >
                    Cancelar
                </v-btn>

                 <!-- && canDelete" -->
                <v-btn v-if="!isNew"
                    color="error"
                    class="mt-2 mb-4"
                    style="width:100px"
                    @click="onBtnShowDeleteModal()"
                >
                    Borrar
                </v-btn>
            </v-row>
            </v-form>
          </v-card>
        </v-container>

        <!-- Modales de borrado -->
        <!-- Borrar Material -->
        <v-dialog
          v-model="showDeleteDialog"
          transition="dialog-bottom-transition"
          persistent
          width="500"
        >
          <v-card>
            <v-card-title class="text-h5 grey lighten-2">
              BORRAR PERSONA
            </v-card-title>

            <v-card-text class="mt-2">
              ¿Desea borrar la persona? Esta acción no se podrá deshacer.
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                :loading="loading"
                color="primary"
                @click="onBtnDelete"
              >
                Aceptar
              </v-btn>
              <v-btn
                @click="onBtnCancelDelete"
              >
                Cancelar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
         
        <!-- Borrar Categoria -->
        <v-dialog
          v-model="showPerCatDeleteDialog"
          transition="dialog-bottom-transition"
          persistent
          width="500"
        >
          <v-card>
            <v-card-title class="text-h5 grey lighten-2">
              BORRAR CATEGORÍA
            </v-card-title>

            <v-card-text class="mt-2">
              ¿Desea borrar la categoría?
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                :loading="perCatLoading"
                color="primary"
                @click="onBtnPerCatDelete"
              >
                Aceptar
              </v-btn>
              <v-btn
                @click="onBtnCancelPerCatDelete"
              >
                Cancelar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!-- Borrar contacto -->
        <v-dialog
          v-model="showPerConDeleteDialog"
          transition="dialog-bottom-transition"
          persistent
          width="500"
        >
          <v-card>
            <v-card-title class="text-h5 grey lighten-2">
              BORRAR CONTACTO
            </v-card-title>

            <v-card-text class="mt-2">
              ¿Desea borrar el contacto?
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                :loading="perConLoading"
                color="primary"
                @click="onBtnPerConDelete"
              >
                Aceptar
              </v-btn>
              <v-btn
                @click="onBtnCancelPerConDelete"
              >
                Cancelar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!-- Borrar contacto -->
        <v-dialog
          v-model="showPerOpeDeleteDialog"
          transition="dialog-bottom-transition"
          persistent
          width="500"
        >
          <v-card>
            <v-card-title class="text-h5 grey lighten-2">
              BORRAR OPERACIÓN
            </v-card-title>

            <v-card-text class="mt-2">
              ¿Desea borrar la operación?
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                :loading="perOpeLoading"
                color="primary"
                @click="onBtnPerOpeDelete"
              >
                Aceptar
              </v-btn>
              <v-btn
                @click="onBtnCancelPerOpeDelete"
              >
                Cancelar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!-- Borrar artículo -->
        <v-dialog
          v-model="showPerTarDeleteDialog"
          transition="dialog-bottom-transition"
          persistent
          width="500"
        >
          <v-card>
            <v-card-title class="text-h5 grey lighten-2">
              BORRAR ARTÍCULO
            </v-card-title>

            <v-card-text class="mt-2">
              ¿Desea borrar la artículo?
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                :loading="perTarLoading"
                color="primary"
                @click="onBtnPerTarDelete"
              >
                Aceptar
              </v-btn>
              <v-btn
                @click="onBtnCancelPerTarDelete"
              >
                Cancelar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog
          v-model="showNewUsrDialog"
          transition="dialog-bottom-transition"
          persistent
          width="500"
        >
          <v-card>
            <v-card-title class="text-h5 grey lighten-2">
              CREAR USUARIO
            </v-card-title>

            <v-card-text class="mt-2">
              Introduzca el nombre de usuario:
              <v-text-field
                  class="mt-3"
                  outlined
                  dense
                  text
                  hide-details
                  v-model="newUsrNom"
                  label="Nombre de usuario"
              ></v-text-field>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                :loading="perTarLoading"
                color="primary"
                :disabled="newUsrNom == ''"
                @click="crearUsuario(newUsrNom)"
              >
                Aceptar
              </v-btn>
              <v-btn
                @click="onBtnCancelNewUsrDelete"
              >
                Cancelar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
    </div>
    
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import ArtFind from "../components/ArtFind.vue";
import DateFind from "../components/DateFind.vue";
import EmpFind from "../components/EmpFind.vue";
import EmpresasRelacionadas from "../components/EmpresasRelacionadas.vue";
import OpeFind from "../components/OpeFind.vue";
import PerFind from "../components/PerFind.vue";
import PerCatFind from "../components/PerCatFind.vue";
import UsrFind from "../components/UsrFind.vue";
export default ({
    components: {
        ArtFind, DateFind, EmpFind, EmpresasRelacionadas, OpeFind, PerFind, PerCatFind, UsrFind
    },
    computed: {
        ...mapState(['empId', 'perId', 'urlRaiz'])
    },
    data () {
        return {
            tab: null,
            loading: false,
            isNew: true,
            isValid: false,
            // canDelete: false,
            showDeleteDialog: false,
            showPerCatDeleteDialog: false,
            showPerConDeleteDialog: false,
            showPerOpeDeleteDialog: false,
            showPerTarDeleteDialog: false,
            showNewUsrDialog: false,
            per: {
                PerId: 0,
                PerCod: '',
                PerNom: '',
                PerNomCial: '',
                PerNif: '',
                PerAbr: '',
                PerObs: '',
                PerSup: {},
                Usr: null
            },
            newUsrNom: '',
            // Datos tabla empresas
            empDataTotal: 0,
            empDataList: [],
            // Datatable categorias
            perCatLoading: false,
            perCatTotalDataRows: 0,
            perCatDataRows: [],
            perCatOptions: {itemsPerPage: 100},
            perCatHeaders: [
                { text: 'Categorías relacionadas', width:300, sortable: false, value: 'PerCatNom' },
                { text: '', width:100, sortable: false, value: 'Opt', align: 'end' }
            ],
            // Datatable contactos
            perConLoading: false,
            perConTotalDataRows: 0,
            perConDataRows: [],
            perConOptions: {itemsPerPage: 100},
            perConHeaders: [
                { text: 'Código', width:75, sortable: false, value: 'PerConCod' },
                { text: 'Nombre', width:100, sortable: false, value: 'PerConNom' },
                { text: 'Telefono 1', width:100, sortable: false, value: 'PerConTel1' },
                { text: 'Telefono 2', width:100, sortable: false, value: 'PerConTel2' },
                { text: 'Fax', width:100, sortable: false, value: 'PerConFax' },
                { text: 'Mail', width:200, sortable: false, value: 'PerConMail' },
                { text: 'Observaciones', width:150, sortable: false, value: 'PerConObs' },
                { text: '', width:100, sortable: false, value: 'Opt', align: 'end' }
            ],
            // Datatable operaciones
            perOpeLoading: false,
            perOpeTotalDataRows: 0,
            perOpeDataRows: [],
            perOpeOptions: {itemsPerPage: 100},
            perOpeHeaders: [
                { text: 'Empresa', width:325, sortable: false, value: 'Emp.EmpNom' },
                { text: 'Operación', width:100, sortable: false, value: 'Ope.OpeNom' },
                { text: '', width:100, sortable: false, value: 'Opt', align: 'end' }
            ],
            // Datatable articulos (PerTar)
            perTarLoading: false,
            perTarTotalDataRows: 0,
            perTarDataRows: [],
            perTarOptions: {itemsPerPage: 100},
            perTarHeaders: [
                { text: 'Empresa', width:325, sortable: false, value: 'Emp.EmpNom' },
                { text: 'Operación', width:100, sortable: false, value: 'Ope.OpeNom' },
                { text: 'Artículo', width:200, sortable: false, value: 'Art.ArtNom' },
                { text: 'Fecha desde', width:100, sortable: false, value: 'FechaDesde' },
                { text: 'Fecha hasta', width:100, sortable: false, value: 'FechaHasta' },
                { text: '', width:100, sortable: false, value: 'Opt', align: 'end' }
            ],
            // Edición categorias
            editPerCat: false, // Indica si se esta editando la nueva categoria
            newPerCat: {},
            selectedPerCat: null, // Indica la categoria seleccionada para el borrado
            // Edición contactos
            editNewPerCon: false, // Indica si se esta editando el nuevo contacto
            newPerCon: {}, // Datos del nuevo contacto
            perConEditData: null, // Datos del contacto a editar
            selectedPerCon: null, // Contacto seleccionado para borrar
            // Edición operaciones
            editNewPerOpe: false, // Indica si se esta editando la nueva operación
            newPerOpe: {}, // Datos de la nueva operación
            perOpeEditData: null, // Datos de la operación a editar
            selectedPerOpe: null, // Operación seleccionada para borrar
            // Edición articulos
            editNewPerTar: false, // Indica si se esta editando el nuevo articulo
            newPerTar: {},
            selectedPerTar: null, // Indica el articulo seleccionado para el borrado
        }
    },
    // watch: {
      // per: {
      //   handler () {
      //       this.validate();
      //   },
      //   deep: true,
      // },
    // },
    mounted() {
      this.isNew = this.$route.meta.isNew;
      
      if(!this.isNew) {
        this.per.PerId = this.$route.params.id;
        this.loadData();
      } else {
        // Usar eager en la v-tab-item para poder tener la ref cargada
        this.$refs.relatedEmps.loadDefaultEmps();
      }
    },
    methods: {
      goToList() {
        this.$router.push('/personas');
      },

      //#region Llamadas a la API
      getDataFromApi() {
        this.loading = true;
        return new Promise((resolve) => {
          const controllerParameters = {
            Action: 'GET_PER',
            PerId: this.per.PerId,
            EmpId: this.empId
          };       
          const AuthToken = localStorage.getItem('token');
          axios({ method: "POST", "url": this.urlRaiz + "/api/per", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
          .then(result => {
            const item = result.data.Per;
            const perTar = result.data.PerTar;
            // const canDelete = result.data.CanDelete;

            setTimeout(() => {
                this.loading = false;
                resolve({
                  item,
                  perTar
                  // canDelete
                })
            }, 1000)
          })
          .catch(error => {
            if(error != null) {
                this.loading = false;
                alert('Error al cargar la persona.');
                this.goToList();
            }
          });
        })
      },
      postDataToApi() {
        this.loading = true;

        // Asignamos los IDs de los objetos hijos
        this.per.PerIdSup = this.per.PerSup != null ? this.per.PerSup.PerId : null;
        const perCatIds = this.perCatDataRows.map(m => m.PerCatId);
        const empIds = this.empDataList.map(m => m.EmpId);
        const perOpes = this.perOpeDataRows.map(m => { return { EmpId: m.EmpId, OpeId: m.OpeId }; });
        const perTars = this.perTarDataRows.map(m => { 
          return { 
            EmpId: m.EmpId, 
            OpeId: m.OpeId, 
            ArtId: m.ArtId,
            FechaDesde: m.FechaDesde,
            FechaHasta: m.FechaHasta
          };
        });

        return new Promise((resolve) => {
          const controllerParameters = {
            Action: 'SAVE_ENTITY',
            EmpId: this.empId,
            Per: this.per,
            PerCatIds: perCatIds,
            EmpIds: empIds,
            PerCons: this.perConDataRows,
            PerOpes: perOpes,
            PerTars: perTars,
            NewUsr: this.per.Usr != null ? this.per.Usr.new : false
          };       
          const AuthToken = localStorage.getItem('token');
          axios({ method: "POST", "url": this.urlRaiz + "/api/per", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
          .then(result => {
            const item = result.data;

            setTimeout(() => {
                this.loading = false;
                resolve({
                  item
                })
            }, 1000)
          })
          .catch(error => {
            if(error != null) {
                this.loading = false;
                alert('Error al guardar la persona.');
            }
          });
        })
      },
      deleteDataFromApi() {
        this.loading = true;
        return new Promise((resolve) => {
          const controllerParameters = {
            Action: 'DEL_ENTITY',
            PerId: this.per.PerId
          };       
          const AuthToken = localStorage.getItem('token');
          axios({ method: "POST", "url": this.urlRaiz + "/api/per", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
          .then(result => {
            const item = result.data;

            setTimeout(() => {
                this.loading = false;
                resolve({
                  item
                })
            }, 1000)
          })
          .catch(error => {
            if(error != null) {
                this.loading = false;
                alert('Error al borrar la persona.');
            }
          });
        })
      },
      //#endregion

      //#region Carga de datos
      loadData() {
        this.getDataFromApi()
          .then(data => {
            if(data.item == null) {
              alert('Persona no encontrada.');
              this.goToList();
              return;
            }

            this.per = data.item;
            // this.canDelete = data.canDelete;
            
            // Cargamos los datos de empresas / categorías / contactos / operaciones / articulos
            this.empDataTotal = data.item.PerEmp.length;
            this.empDataList = [];
            data.item.PerEmp.forEach(i => {
              this.empDataList.push({
                EmpId: i.Emp.EmpId,
                EmpNom: i.Emp.EmpNom
              });
            });

            this.perCatTotalDataRows = data.item.PerCatPer.length;
            this.perCatDataRows = [];
            data.item.PerCatPer.forEach(i => {
              this.perCatDataRows.push({
                PerCatId: i.PerCat.PerCatId,
                PerCatNom: i.PerCat.PerCatNom
              });
            });

            this.perConTotalDataRows = data.item.PerCon.length;
            this.perConDataRows = data.item.PerCon;
            
            this.perOpeTotalDataRows = data.item.PerOpe.length;
            this.perOpeDataRows = data.item.PerOpe;

            this.perTarTotalDataRows = data.perTar.length;
            this.perTarDataRows = data.perTar;
          });
      },
      //#endregion

      //#region Gestión usuario
      onBtnCrearUsuario() {
        // Si no esta especificado el NIF, se abre un dialogo para 
        // preguntar el login al usuario
        if(this.per.PerNif == '') {
          this.onBtnShowNewUsrModal();
        }
        else {
          this.crearUsuario(this.per.PerNif);
        }
      },
      crearUsuario(usrNom) {
        this.per.Usr = {
          UsrId: 0,
          UsrNom: this.per.PerNom,
          UsrLogin: usrNom,
          new: true
        }
        this.showNewUsrDialog = false;
      },
      //#endregion

      //#region  Añadir categorías/contacto/operacion
      onBtnNuevaCategoria() {
        this.editPerCat = true;
        this.newPerCat = null;
      },
      onBtnNuevoContacto() {
        this.editNewPerCon = true;
        this.newPerCon = { PerConId: 0, edit: false };
      },
      onBtnNuevaOperacion() {
        this.editNewPerOpe = true;
        this.newPerOpe = { Ope: null, Emp: null, edit: false };
      },
      onBtnNuevoPerTar() {
        this.editNewPerTar = true;
        this.newPerTar = { Ope: null, Emp: null, Art: null, FechaDesde: null, FechaHasta: null };
      },
      //#endregion

      //#region Click guardado
      onBtnSave() {
          this.validate();
          if(!this.isValid) { return; }
          this.postDataToApi().then(data => {
            if(data.item.PerId != null) {
                this.goToList();
            } else {
              if (data.item != '') {
                alert(data.item);
              } else {
                alert('Error al guardar la persona.');
              }
            }
          });
      },
      onBtnSaveCategoria() {
        if(this.newPerCat == null) { return; }
        this.perCatDataRows.push(this.newPerCat);
        this.perCatTotalDataRows += 1;
        this.newPerCat = null;
        this.editPerCat = false; 
      },
      addEmpIfNotExist(emp) {
        const empCount = this.empDataList.filter(f => f.EmpId == emp.EmpId).length;
        // Si no existe añadimos la empresa
        if(empCount == 0) {
          const newEmp = {
            EmpId: emp.EmpId,
            EmpNom: emp.EmpNom
          };
          this.empDataList.push(newEmp);
          this.empDataTotal += 1;
        }
      },
      onBtnSavePerCon() {
        if(this.newPerCon.PerConNom == '') { return; }
        this.perConDataRows.push(this.newPerCon);
        this.perConTotalDataRows += 1;
        this.newPerCon = {};
        this.editNewPerCon = false;
      },
      onBtnSavePerOpe() {
        if(!this.isValidPerOpe(this.newPerOpe)) { return; }
        
        this.newPerOpe.EmpId = this.newPerOpe.Emp.EmpId;
        this.newPerOpe.OpeId = this.newPerOpe.Ope.OpeId;
        this.perOpeDataRows.push(this.newPerOpe);
        this.perOpeTotalDataRows += 1;

        this.addEmpIfNotExist(this.newPerOpe.Emp);
        
        this.newPerOpe = {};
        this.editNewPerOpe = false;
      },
      onBtnSavePerTar() {
        if(!this.isValidPerTar(this.newPerTar)) { return; }
        
        this.newPerTar.EmpId = this.newPerTar.Emp.EmpId;
        this.newPerTar.OpeId = this.newPerTar.Ope.OpeId;
        this.newPerTar.ArtId = this.newPerTar.Art.ArtId;
        this.perTarDataRows.push(this.newPerTar);
        this.perTarTotalDataRows += 1;

        this.addEmpIfNotExist(this.newPerTar.Emp);
        
        this.newPerTar = {};
        this.editNewPerTar = false;
      },
      //#endregion

      isEditRow(item) {
        return item.edit ? 'edit-row' : '';
      },
      //#region Editar contactos
      cancelPerConEdit() {
        const editPerCons = this.perConDataRows.filter(f => f.edit);
        editPerCons.forEach(perCon => {
          perCon.edit = false;
        });
        
        this.perConEditData = null;
        this.$forceUpdate();
      },
      onClickRowEditarPerCon(item) {
        if(this.loading) { return; }
        if(item.edit) { return; }
        
        this.cancelPerConEdit();
        this.perConEditData = {
          PerConCod: item.PerConCod,
          PerConNom: item.PerConNom,
          PerConTel1: item.PerConTel1,
          PerConTel2: item.PerConTel2,
          PerConFax: item.PerConFax,
          PerConMail: item.PerConMail,
          PerConObs: item.PerConObs
        };

        item.edit = true;
        this.$forceUpdate();
      },
      onBtnEditPerCon(dtData) {
        if(this.perConEditData == null) { return; }
        if(this.perConEditData.PerConNom == '') { return; }
        if(dtData != null) {
          dtData.PerConCod = this.perConEditData.PerConCod;
          dtData.PerConNom = this.perConEditData.PerConNom;
          dtData.PerConTel1 = this.perConEditData.PerConTel1;
          dtData.PerConTel2 = this.perConEditData.PerConTel2;
          dtData.PerConFax = this.perConEditData.PerConFax;
          dtData.PerConMail = this.perConEditData.PerConMail;
          dtData.PerConObs = this.perConEditData.PerConObs;

          this.cancelPerConEdit();
        }
      },
      //#endregion

      //#region Editar operaciones
      cancelPerOpeEdit() {
        const editPerOpes = this.perOpeDataRows.filter(f => f.edit);
        editPerOpes.forEach(perOpe => {
          perOpe.edit = false;
        });
        
        this.perOpeEditData = null;
        this.$forceUpdate();
      },
      onClickRowEditarPerOpe(item) {
        if(this.loading) { return; }
        if(item.edit) { return; }
        this.cancelPerOpeEdit();
        this.perOpeEditData = {
          Emp: item.Emp,
          Ope: item.Ope
        };

        item.edit = true;
        this.$forceUpdate();
      },
      onBtnEditPerOpe(dtData) {
        if(!this.isValidPerOpe(this.perOpeEditData)) { return; }
        if(dtData != null) {
          dtData.Emp = this.perOpeEditData.Emp;
          dtData.EmpId = this.perOpeEditData.Emp.EmpId;
          dtData.Ope = this.perOpeEditData.Ope;
          dtData.OpeId = this.perOpeEditData.Ope.OpeId;

          this.addEmpIfNotExist(this.perOpeEditData.Emp);

          this.cancelPerOpeEdit();
        }
      },
      //#endregion

      //#region Click borrar
      onBtnDelete() {
        this.deleteDataFromApi().then(data => {
          if (data.item.Success) {
            this.showDeleteDialog = false;
            this.goToList();
          } else {
            alert(data.item.Message);
          }
        });
      },
      //#endregion

      //#region  Cancelar creación de categoría/contacto/operacion
      onBtnCancelCategoria() {
        this.editPerCat = false;
      },
      onBtnCancelPerCon() {
        this.editNewPerCon = false;
        this.newPerCon = {};
      },
      onBtnCancelPerOpe() {
        this.editNewPerOpe = false;
        this.newPerOpe = {};
      },
      onBtnCancelPerTar() {
        this.editNewPerTar = false;
        this.newPerTar = {};
      },
      //#endregion

      //#region  Eliminar categorías/empresas
      removePerCat(item) {
        var index = this.perCatDataRows.map(m => m.PerCatId).indexOf(item.PerCatId);
        if (index != -1) {
          this.perCatDataRows.splice(index, 1);
          this.perCatTotalDataRows -= 1;  
        }
      },
      removePerCon(item) {
        var index = this.perConDataRows.indexOf(item);
        if (index != -1) {
          this.perConDataRows.splice(index, 1);
          this.perConTotalDataRows -= 1;
        }
      },
      removePerOpe(item) {
        var index = this.perOpeDataRows.indexOf(item);
        if (index != -1) {
          this.perOpeDataRows.splice(index, 1);
          this.perOpeTotalDataRows -= 1;
        }
      },
      removeEmpPerOpes(emp) {
        const empPerOpes = this.perOpeDataRows.filter(f => f.EmpId == emp.EmpId);
        empPerOpes.forEach(perOpe => {
          this.removePerOpe(perOpe);
        });
      },
      removePerTar(item) {
        var index = this.perTarDataRows.indexOf(item);
        if (index != -1) {
          this.perTarDataRows.splice(index, 1);
          this.perTarTotalDataRows -= 1;
        }
      },
      removeEmpPerTars(emp) {
        const empPerTars = this.perTarDataRows.filter(f => f.EmpId == emp.EmpId);
        empPerTars.forEach(perTar => {
          this.removePerTar(perTar);
        });
      },
      onBtnPerCatDelete() {
        this.removePerCat(this.selectedPerCat);
        this.selectedPerCat = null;
        this.showPerCatDeleteDialog = false;
      },
      onBtnPerConDelete() {
        this.removePerCon(this.selectedPerCon);
        this.selectedPerCon = null;
        this.showPerConDeleteDialog = false;
      },
      onBtnPerOpeDelete() {
        this.removePerOpe(this.selectedPerOpe);
        this.selectedPerOpe = null;
        this.showPerOpeDeleteDialog = false;
      },
      onBtnPerTarDelete() {
        this.removePerTar(this.selectedPerTar);
        this.selectedPerTar = null;
        this.showPerTarDeleteDialog = false;
      },
      //#endregion

      //#region  Gestión de modales
      onBtnShowDeleteModal() {
        this.showDeleteDialog = true;
      },
      onBtnShowPerCatDeleteModal(item) {
        this.selectedPerCat = item;
        this.showPerCatDeleteDialog = true;
      },
      onBtnShowPerConDeleteModal(item) {
        this.selectedPerCon = item;
        this.showPerConDeleteDialog = true;
      },
      onBtnShowPerOpeDeleteModal(item) {
        this.selectedPerOpe = item;
        this.showPerOpeDeleteDialog = true;
      },
      onBtnShowPerTarDeleteModal(item) {
        this.selectedPerTar = item;
        this.showPerTarDeleteDialog = true;
      },
      onBtnShowNewUsrModal() {
        this.newUsrNom = '';
        this.showNewUsrDialog = true;
      },
      onBtnCancelDelete() {
        this.showDeleteDialog = false;
      },
      onBtnCancelPerCatDelete() {
        this.selectedPerCat = null;
        this.showPerCatDeleteDialog = false;
      },
      onBtnCancelPerConDelete() {
        this.selectedPerCon = null;
        this.showPerConDeleteDialog = false;
      },
      onBtnCancelPerOpeDelete() {
        this.selectedPerOpe = null;
        this.showPerOpeDeleteDialog = false;
      },
      onBtnCancelPerTarDelete() {
        this.selectedPerOpe = null;
        this.showPerOpeDeleteDialog = false;
      },
      onBtnCancelNewUsrDelete() {
        this.showNewUsrDialog = false;
      },
      //#endregion

      //#region Evento actualización empresas
      updateEmpData(data) {
        this.empDataList = data.list;
        this.empDataTotal = data.total;
      },
      empDeleted(item) {
        this.removeEmpPerOpes(item);
        this.removeEmpPerTars(item);
      },
      //#endregion

      //#region  Validación del formulario
      validate() {
        this.$refs.form.validate();
        // if (this.per == null) {
        //     this.isValid = false;
        //     return;
        // }
        // this.isValid = this.per.PerNom != null && this.per.PerNom != '';
      },
      isValidPerOpe(perOpe) {
        if(perOpe.Emp == null) { return false; }
        if(perOpe.Ope == null) { return false; }
        
        const equalOpes = this.perOpeDataRows.filter(f => f.EmpId == perOpe.Emp.EmpId && f.OpeId == perOpe.Ope.OpeId);
        return equalOpes.length == 0;
      },
      isValidPerTar(perTar) {
        if(perTar.Emp == null) { return false; }
        if(perTar.Ope == null) { return false; }
        if(perTar.Art == null) { return false; }
        
        const equalPerTars = this.perTarDataRows
          .filter(f => f.EmpId == perTar.Emp.EmpId && f.OpeId == perTar.Ope.OpeId && f.ArtId == perTar.Art.ArtId);
        return equalPerTars.length == 0;
      },
      //#endregion

      formatDate (value) {
        const date = new Date(value);
        return String(date.getDate()).padStart(2, '0') + '/' + String(date.getMonth() + 1).padStart(2, '0')  + '/' + date.getFullYear();
      }
    }
})
</script>

<style>
  .perConDt tr.edit-row > td{
    padding-left: 4px !important;
    padding-right: 4px !important;
  }
</style>
